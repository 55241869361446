<script setup>
import { onBeforeMount, ref, reactive } from "vue";
import useClients from "../../../hooks/useClients";
import { useRoute } from "vue-router/auto";
import ClientLayout from "@/layouts/ClientLayout.vue";

const {
  client,
  fetchClient,
  shopifyOauthIntegration,
  wooCommerceCreateApiUser,
} = useClients();
const route = useRoute();

onBeforeMount(async () => {
  await fetchClient(route.params.id);
  console.log(client);
});

const shopifyStoreUrl = ref(null);
const wooCommerceUrl = ref(null);
const wooCommerceConsumerKey = ref(null);
const wooCommerceConsumerSecret = ref(null);

const connectToShopify = () => {
  const params = {
    shop: shopifyStoreUrl.value,
  };
  shopifyOauthIntegration(params);
};

const connectToWooCommerce = async () => {
  const params = {
    client_id: route.params.id,
    woocommerce_url: wooCommerceUrl.value,
    woocommerce_consumer_key: wooCommerceConsumerKey.value,
    woocommerce_consumer_secret: wooCommerceConsumerSecret.value,
  };
  wooCommerceCreateApiUser(params);
  await fetchClient(route.params.id);
};
</script>

<template>
  <ClientLayout v-if="client" :client="client">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Integrations</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="min-h-100">
          <v-card class="px-6 py-6 h-100">
            <v-row class="p-3">
              <v-col cols="4" class="d-flex align-center">
                <img
                  src="https://lm-web-assets.s3.us-east-2.amazonaws.com/logos/Shopify.jpg"
                  alt="Shopify"
                  style="width: 100%"
                />
              </v-col>
              <v-col cols="12">
                <small>
                  Shopify allows Connect to receive notifications of new & shipped orders
                  actions.
                </small>
                <form class="mt-3" action="/shopify/authorize" method="get">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        id="shopifyAppName"
                        label="APP STORE URL"
                        class="form-control form-control-sm"
                        outlined
                        v-model="shopifyStoreUrl"
                        density="compact"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn color="success" small @click="connectToShopify"
                        >Connect to Shopify</v-btn
                      >
                    </v-col>
                  </v-row>
                </form>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="px-6 py-6">
            <v-row class="p-3">
              <v-col
                cols="4"
                class="d-flex align-center text-h5 font-weight-bold items-center"
              >
                <img src="/woocommerce_logo.png" alt="Shopify" style="width: 75%" />
                Woocommerce
              </v-col>
              <v-col cols="12">
                <small>
                  WooCommerce allows Connect to receive notifications of new & shipped
                  orders actions.
                </small>
                <form class="mt-3" action="/shopify/authorize" method="get">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        id="wooCommerceUrl"
                        placeholder="https://example.com"
                        label="Store URL"
                        class="form-control form-control-sm"
                        outlined
                        v-model="wooCommerceUrl"
                        density="compact"
                        variant="outlined"
                        :disabled="client.has_woocommerce_user"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        id="wooCommerceConsumerKey"
                        placeholder="ck_xxxx"
                        label="Consumer Key"
                        class="form-control form-control-sm"
                        outlined
                        v-model="wooCommerceConsumerKey"
                        density="compact"
                        variant="outlined"
                        :disabled="client.has_woocommerce_user"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        id="wooCommerceConsumerSecret"
                        label="Consumer Secret"
                        placeholder="cs_xxxx"
                        class="form-control form-control-sm"
                        outlined
                        v-model="wooCommerceConsumerSecret"
                        density="compact"
                        variant="outlined"
                        :disabled="client.has_woocommerce_user"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        color="success"
                        :disabled="client.has_woocommerce_user"
                        small
                        @click="connectToWooCommerce"
                        >{{
                          client.has_woocommerce_user == true
                            ? "User already connected"
                            : "Connect Woocommerce"
                        }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </form>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </ClientLayout>
</template>
